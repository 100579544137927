body {
  margin: 0;
  padding: 0;
  background-color: white;
  /* Set the background color to black for the entire body */
  color: whiblackte;
  /* Set the default text color to white */
}

.App {
  text-align: center;
}

.App-top-bar {
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.App-title {
  font-size: 3rem;
  margin: 0;
  padding: 0;
  color: black;
}

.App-menu {
  list-style: none;
  padding: 0;
  display: flex;
}

.App-menu li {
  margin-right: 20px;
}

.App-menu a {
  text-decoration: none;
  color: black;
}

.Big-title {
  font-size: 5rem;
  font-weight: 500;
  /* Set font weight to medium (500) */
}

.Whitepaper {
  text-align: left;
}

.Mid-Text {
  font-size: 2rem;
}

.Partners {
  text-align: left;
}

.UseCases {
  text-align: center;
}

.Two-Columns {
  text-align: left;
  display: flex;
  justify-content: space-between;
}

.Without-DeWa,
.With-DeWa {
  text-align: left;
  width: 45%;
  /* Adjust as needed for spacing and responsiveness */
}

main {
  padding: 50px;
}

.moreInfo {
  margin-top: 300;
}

/* Basic Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Navbar Styles */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #333;
  color: white;
  padding: 10px 20px;
}

.logo {
  display: flex;
  align-items: center;
  font-size: 1.5em;
  font-weight: bold;
}

.logo img {
  margin-right: 10px;
}

/* Hamburger Menu Icon */
.menu-icon {
  display: none;
  cursor: pointer;
}

.menu-icon .line {
  width: 25px;
  height: 3px;
  background-color: white;
  margin: 5px;
}

.nav-link {
  margin-left: 20px;
  text-decoration: none;
  /* Removes underline */
  color: white;
  font-size: 1.2em;
  font-weight: bold;
  padding: 10px;
  transition: color 0.3s ease;
}

.nav-link-home:hover {
  color: #B6E3FC;
  /* Red on hover */
}

.nav-link-about:hover {
  color: #DDFEB3;
  /* Blue on hover */
}

.nav-link-usecases:hover {
  color: #FDFFBC;
  /* Green on hover */
}

.nav-link-partners:hover {
  color: #FBE9BA;
  /* Yellow on hover */
}

.nav-link-imprint:hover {
  color: #F6C5C4;
  /* Purple on hover */
}

.background-rectangle {
  position: absolute;
  top: 0;
  left: 0;
  width: 450px;
  /* Adjust size as needed */
  height: 680px;
  /* Adjust size as needed */
  border-radius: 25px;
  /* Adjust for rounded corners */
}

.rectangle-1 {
  transform: rotate(70deg);
  z-index: -1;
  background-color: rgba(182, 227, 252, 0.5);
  /* Adjust opacity (0.5 for 50%) */
  top: 20px;
  /* Adjust the top position as needed */
  left: 0px;
  /* Adjust the left position as needed */
}

.rectangle-2 {
  transform: rotate(120deg);
  z-index: -2;
  background-color: rgba(246, 197, 196, 0.5);
  /* Adjust opacity (0.5 for 50%) */
  top: 20px;
  /* Adjust the top position as needed */
  left: 900px;
  /* Adjust the left position as needed */
}

.rectangle-3 {
  transform: rotate(170deg);
  z-index: -2;
  background-color: rgba(221, 254, 179, 0.5);
  /* Adjust opacity (0.5 for 50%) */
  top: 500px;
  /* Adjust the top position as needed */
  left: 900px;
  /* Adjust the left position as needed */
}

/* App.css */
.boxSubTitle {
  padding: 20px 30px;
  font-size: 25px;
  font-weight: bold;
  border-radius: 50px;
  margin: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  text-align: center;
  background-color: transparent;
  /* Transparent background by default */
  color: white;
  /* White text by default */
}

/* Hover styles */
.boxSubTitle:hover {
  /* Common hover effect for all subtitles */
  background-color: rgba(255, 255, 255, 0.3);
  /* Example hover color, adjust as needed */
}

.email-link {
  text-decoration: none;
  color: 'blue';
  /* Replace with your desired color */
}
.uppercase-text {
  text-transform: uppercase;
}

/* Individual subtitle colors */
.boxSubTitle-egov {
  background-color: #B5E3FB;
  color: black;
}

.boxSubTitle-studentid {
  background-color: #D9FEB3;
  color: black;
}

.boxSubTitle-kyc {
  background-color: #FDFFBC;
  color: black;
}

.boxSubTitle-healthcare {
  background-color: #FBE9BA;
  color: black;
}

.boxSubTitle-sso {
  background-color: #F6C5C4;
  color: black;
}


.boxSubTitle-egov:hover {
  background-color: #7EADC6;
  /* Lighter gold */
}


.boxSubTitle-studentid:hover {
  background-color: #A0C080;
  /* Yellow Green */
}


.boxSubTitle-kyc:hover {
  background-color: #BEBF8C;
  /* Yellow Green */
}


.boxSubTitle-healthcare:hover {
  background-color: #BFAF84;
  /* Yellow Green */
}


.boxSubTitle-sso:hover {
  background-color: #B78A89;
  /* Yellow Green */
}